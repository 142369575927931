// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-bok-js": () => import("./../../../src/pages/bok.js" /* webpackChunkName: "component---src-pages-bok-js" */),
  "component---src-pages-gavuhandan-js": () => import("./../../../src/pages/gavuhandan.js" /* webpackChunkName: "component---src-pages-gavuhandan-js" */),
  "component---src-pages-hugskot-js": () => import("./../../../src/pages/hugskot.js" /* webpackChunkName: "component---src-pages-hugskot-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-limir-js": () => import("./../../../src/pages/limir.js" /* webpackChunkName: "component---src-pages-limir-js" */),
  "component---src-pages-nevndir-js": () => import("./../../../src/pages/nevndir.js" /* webpackChunkName: "component---src-pages-nevndir-js" */),
  "component---src-pages-portal-js": () => import("./../../../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-reglar-js": () => import("./../../../src/pages/reglar.js" /* webpackChunkName: "component---src-pages-reglar-js" */),
  "component---src-pages-samband-js": () => import("./../../../src/pages/samband.js" /* webpackChunkName: "component---src-pages-samband-js" */),
  "component---src-pages-um-js": () => import("./../../../src/pages/um.js" /* webpackChunkName: "component---src-pages-um-js" */)
}

